.container {
  text-align: center;
  margin: 1rem 0.5rem 0;
  width: 100%;
}

.container h1 {
  font-size: calc(4rem + 2vw);
  text-transform: uppercase;
}

.container p {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 2rem;
  margin: 1.5rem 0 3rem;
}